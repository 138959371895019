<template>
    <div class="sidebar-container">
        <div class="wechat-icon">
            <div class="wechat-icon-content">
                <img src="@/assets/wechat_logo.png" alt="微信图标" class="wechat-logo" />
                <div class="description">
                    <span>客服</span>
                </div>
            </div>
            <div class="qr-code-div">
                <img src="@/assets/WechatIMG17.jpg" alt="微信二维码" class="qr-code-image" />
            </div>
        </div>
    </div>
</template>

<style>
.sidebar-container {
    position: relative;
    .wechat-icon {
        position: relative;

        cursor: pointer;

        .wechat-icon-content {
            display: flex;
            flex-direction: column;
            align-items: center; /* 垂直居中对齐 */
        }

        .wechat-logo {
            width: 50px;
            height: 40px;
        }

        .description {
            margin-top: 5px; /* 调整图标和文字之间的间距 */
            text-align: center;
            span {
                display: block;
            }
        }

        .qr-code-div {
            display: none;
            position: absolute; /* 绝对定位来脱离文档流 */
            right: 100%!important; /* 向左完全移出图标 */
            top: 0%;
            transform: translateY(-50%) scale(0.8);
            transition: transform 0.3s ease;
            border: 1px solid #ccc;
            background-color: #fff;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
            z-index: 1000; /* 确保二维码显示在所有组件上方 */
            white-space: nowrap; /* 防止内容换行 */
            .qr-code-image {
                width: 200px;
                height: 200px;
            }
        }

        &:hover .qr-code-div {
            display: block;
            transform: translateY(-50%) scale(1);
        }
    }
}
</style>
