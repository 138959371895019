<template>
    <nav class="navigation-container">
        <div class="logo">
<!--            <a href="/" class="logo-link">ZeroAI</a>-->
            <a href="/" class="logo-link">
                <img src="../../public/favicon.jpg" alt="ZeroAI Logo" width="80px" height="40px" />
            </a>
        </div>
        <div class="right-section">
            <div class="purchase-container" @mouseover="showDropdown" @mouseout="hideDropdown">
                <a href="#" class="purchase-link">购买</a>
                <div v-show="isDropdownVisible" class="dropdown-menu">
                    <a href="#" @click.prevent="showNotAvailableMessage" class="dropdown-item"
                        >充值</a
                    >
                    <a href="#" @click.prevent="showRedeemDialog" class="dropdown-item">兑换码</a>
                </div>
            </div>

            <el-popover
                placement="bottom"
                width="120"
                trigger="hover"
                v-model:visible="isSettingsDropdownVisible"
            >
                <div class="settings-dropdown-menu">
                    <a href="/change-password" class="settings-dropdown-item">修改密码</a>
                    <a href="#" @click.prevent="handleAuthAction" class="settings-dropdown-item">{{
                        isLoggedIn ? '登出' : '登录'
                    }}</a>
                </div>
                <template #reference>
                    <el-icon
                        style="cursor: pointer; font-size: 28px; color: #002f5a"
                        class="settings-icon"
                    >
                        <Setting />
                    </el-icon>
                </template>
            </el-popover>

            <el-avatar class="avatar-icon" size="30" @click="onAvatarClick">
                <el-icon style="font-size: 25px">
                    <UserFilled />
                </el-icon>
            </el-avatar>
        </div>

        <RedeemDialog
            :visible="isRedeemDialogVisible"
            @update:visible="setRedeemDialogVisible"
            @redeem="handleRedeem"
        />
    </nav>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import RedeemDialog from '@/components/Redeem.vue'
import { Setting, UserFilled } from '@element-plus/icons-vue'
import { ElPopover, ElIcon, ElAvatar } from 'element-plus'
import { alertService } from '@/utils/alertService'

const router = useRouter()
const isDropdownVisible = ref(false)
const isRedeemDialogVisible = ref(false)
const isSettingsDropdownVisible = ref(false)
const isLoggedIn = ref(false)

// 检查 token 是否存在
const checkAuthStatus = () => {
    isLoggedIn.value = !!localStorage.getItem('token')
}

// 执行登录或登出操作
const handleAuthAction = () => {
    if (isLoggedIn.value) {
        // 执行登出操作
        localStorage.removeItem('token')
        isLoggedIn.value = false
        // 可选：刷新页面或执行其他逻辑
        // 刷新页面
        window.location.reload()
    } else {
        // 跳转到登录页面
        router.push('/login')
    }
}

const showDropdown = () => {
    isDropdownVisible.value = true
}

const hideDropdown = () => {
    isDropdownVisible.value = false
}

const showNotAvailableMessage = () => {
    // ElMessage({
    //     message: '充值功能暂不可用，请使用兑换码购买额度',
    //     type: 'warning',
    //     duration: 2000
    // })

    alertService.showInfo('充值功能暂不可用，请使用兑换码购买额度')
}

const showRedeemDialog = () => {
    isRedeemDialogVisible.value = true
}

const setRedeemDialogVisible = (visible) => {
    isRedeemDialogVisible.value = visible
}

const handleRedeem = (redeemCode) => {
    console.log(`兑换码: ${redeemCode}`)
    // 处理兑换逻辑，例如调用 API
}

const onAvatarClick = () => {
    router.push('/user')
}

// 初始化时检查登录状态
checkAuthStatus()
</script>

<style>
.navigation-container {
    border-bottom: 2px solid #002f5a;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    font-family: 'Arial', sans-serif;
    font-size: 24px;
    font-weight: bold;
}

.logo-link {
    color: #333333;
    text-decoration: none;
}

.logo-link:hover {
    color: #1a8cff;
}

.right-section {
    display: flex;
    align-items: center;
    gap: 20px; /* 使用gap来控制子元素间隔 */
}

.purchase-container {
    position: relative;
}

.purchase-link {
    display: inline;
    color: #002f5a;
    text-decoration: none;
}

.purchase-link:hover {
    color: #1a8cff;
}

.dropdown-menu {
    position: absolute;
    top: 100%!important;
    left: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.dropdown-item {
    display: block;
    padding: 10px 20px;
    color: #002f5a;
    text-decoration: none;
    white-space: nowrap;
}

.dropdown-item:hover {
    background-color: #f4f4f4;
    color: #1a8cff;
}

.settings-popover {
    padding: 0;
    border: none;
    box-shadow: none;
}

.settings-dropdown-menu {
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 14px;
    white-space: nowrap;
}

.settings-dropdown-item {
    color: #002f5a;
    text-decoration: none;
    padding: 5px 0;
}

.settings-dropdown-item:hover {
    color: #1a8cff;
}

.settings-icon {
    cursor: pointer;
}

.avatar-icon {
    cursor: pointer;
}
</style>
