import { createRouter, createWebHistory } from 'vue-router'
import homePage from '@/components/HomePage.vue'
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import UserInfo from '@/components/UserInfo.vue'
import RetrievePassword from '@/components/RetrievePassword.vue'
import UpdatePassword from '@/components/UpdatePassword.vue'
import { setFavicon } from '@/utils/setFavicon.js'

const router = createRouter({
    // eslint-disable-next-line no-undef
    history: createWebHistory(process.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: homePage,
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,

        },
        {
            path: '/register',
            name: 'Register',
            component: Register
        },
        {
            path: '/user',
            name: 'UserInfo',
            component: UserInfo
        },
        {
            path: '/retrieve-password',
            name: 'RetrievePassword',
            component: RetrievePassword
        },
        {
            path: '/change-password',
            name: 'UpdatePassword',
            component: UpdatePassword
        }
    ]
})

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = 'ZeroAI';
    }
    // 动态更新favicon
    if (to.meta && to.meta.favicon) {
        setFavicon(`/${to.meta.favicon}`);
    } else {
        setFavicon('/favicon.jpg'); // 引用默认图标
    }
    next();
});
export default router
