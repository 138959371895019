<template>
    <el-dialog :model-value="localVisible" :title="title" @close="handleClose">
        <el-input type="textarea" v-model="localContent" rows="10" readonly></el-input>
        <div class="dialog-footer">
            <el-button @click="close">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({ visible: Boolean, title: String, content: String })
const emit = defineEmits(['update:visible'])

const localVisible = ref(props.visible)
const localContent = ref(props.content)

// 监听 props 可见性变化
watch(
    () => props.visible,
    (newVal) => {
        localVisible.value = newVal
        // console.log("SSS localVisible", localVisible)
    }
)

// 监听 props 内容变化
watch(
    () => props.content,
    (newVal) => {
        localContent.value = newVal
        // console.log("SSS localContent", localContent)
    }
)

// 监听本地可见状态，用于同步父组件
watch(localVisible, (newVal) => {
    emit('update:visible', newVal)
    // console.log("Local visible changed:", newVal)
})

// 处理对话框关闭
const handleClose = () => {
    localVisible.value = false
}

const close = () => {
    localVisible.value = false
}
</script>

<style scoped>
.dialog-footer {
    text-align: right;
}
</style>
