<template>
    <div class="home">
        <div class="navbar-wrapper-home">
            <Navbar />
        </div>
        <div class="activity-column">
            <div class="activity-title">活动</div>
            <div class="activity-content">添加客户微信可免费获得150词额度兑换码</div>
        </div>
        <div class="deduplication-container">
            <div class="deduplication-content">
                <div class="deduplication-main">
                    <div class="deduplication-input">
                        <el-input
                            class="input-left"
                            type="textarea"
                            v-model="content.input"
                            placeholder="请输入内容..."
                            resize="none"
                            style="width: 100%"
                        ></el-input>
                        <!-- <div class="word-count">输入内容字数：{{ inputWordCount }}</div>
                    <span v-if="errorMessage" class="error-message">{{ errorMessage }}</span>
                    <button class="action-btn" @click="handleDeduplicationInput">降重</button> -->
                    </div>
                    <div class="deduplication-output">
                        <el-input
                            class="input-right"
                            type="textarea"
                            v-model="content.output"
                            placeholder="降重后的内容..."
                            resize="none"
                            style="width: 100%"
                        ></el-input>
                        <!-- <div class="word-count">输出内容字数：{{ outputWordCount }}</div>
                    <div class="quota">剩余额度：{{ quote }}</div>
                    <button class="show-all-btn" :disabled="isNotRemained" @click="showAllContent">
                        显示全部内容
                    </button> -->
                    </div>
                </div>
                <div class="deduplication-footer">
                    <div class="deduplication-footer">
                        <div class="deduplication-footer-left">
                            <div class="word-count">输入内容字数：{{ inputWordCount }}</div>
                            <span v-if="errorMessage" class="error-message">{{
                                errorMessage
                            }}</span>
                            <button class="action-btn" @click="handleDeduplicationInput">
                                降重
                            </button>
                        </div>
                        <div class="deduplication-footer-right">
                            <div class="word-count">输出内容字数：{{ outputWordCount }}</div>
                            <div class="quota">剩余额度：{{ quote }}</div>
                            <button
                                class="show-all-btn"
                                :disabled="isNotRemained"
                                @click="showAllContent"
                            >
                                显示全部内容
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider-container">
                <Sidebar />
            </div>
        </div>
    </div>
</template>

<script setup>
import Navbar from '@/components/Navbar.vue'
// import Slider from '@/components/Slider.vue'
import Sidebar from '@/components/Sidebar.vue'
import { computed, reactive, ref, onMounted } from 'vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import { useRouter } from 'vue-router'
import { ElMessageBox, ElLoading } from 'element-plus'
import store from '@/stores/store.js'

const content = reactive({
    input: '',
    output: ''
})

const wordCount = (text) => {
    const inputText = text.trim()
    if (!inputText) return 0

    const words = inputText.match(/\b[\w']+\b/g) || []
    const wordCount = words.length

    const characters = inputText.match(/[\u4e00-\u9fa5]/g) || []
    const characterCount = characters.length

    return wordCount + characterCount
}

const inputWordCount = computed(() => wordCount(content.input))
const outputWordCount = ref(0)
const score = ref(0)
const quote = ref(0)
const isNotRemained = ref(true) // 用于控制“显示全部内容”按钮状态
const router = useRouter()
const errorMessage = ref('')
const contentId = ref(0)
const handleDeduplicationInput = async () => {
    if (inputWordCount.value < 50) {
        errorMessage.value = '输入字数不少于50'
        return
    }
    errorMessage.value = ''

    try {
        await ElMessageBox.confirm('是否进行降重？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })

        const loading = ElLoading.service({
            lock: true,
            text: '正在处理，请稍候...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })

        const requestParams = {
            input: content.input
        }

        try {
            const responseContent = await apiService.weightLossTask(requestParams)

            // const id = '86331174397419450'
            // const responseContent = await apiService.weightLossContent({ id: id })
            content.output = responseContent.data.content.showContent
            quote.value = responseContent.data.content.balance
            isNotRemained.value = !responseContent.data.isRemained // 更新isRemained状态
            contentId.value = responseContent.data.content.id
            outputWordCount.value = responseContent.data.content.showNumber
        } catch (error) {
            if (error.response?.data?.reason === 401) {
                alertService.showInfo('用户未登录')
                // router.push('/login')
            } else {
                alertService.showError(error.response?.data?.message || '发生未知错误！')
            }
        } finally {
            loading.close()
        }
    } catch (error) {
        if (error !== 'cancel') {
            console.error('MessageBox error: ', error)
        }
    }
}

const showAllContent = async () => {
    try {
        await ElMessageBox.confirm('是否显示剩余内容？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        })

        const loading = ElLoading.service({
            lock: true,
            text: '正在处理，请稍候...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })

        const requestParams = {
            id: contentId.value
        }

        try {
            const responseContent = await apiService.weightLossTaskRemainContent(requestParams)
            content.output = responseContent.data.content.showContent
            quote.value = responseContent.data.content.balance
            isNotRemained.value = !responseContent.data.isRemained // 更新isRemained状态
            contentId.value = responseContent.data.content.id
            score.value = responseContent.data.content.score // 更新得分
            outputWordCount.value = responseContent.data.content.showNumber
        } catch (error) {
            if (error.response?.data?.reason === 401) {
                alertService.showInfo('用户未登录')
                // router.push('/login')
            } else {
                alertService.showError(error.response?.data?.message || '发生未知错误！')
            }
        } finally {
            loading.close()
        }
    } catch (error) {
        if (error !== 'cancel') {
            console.error('MessageBox error: ', error)
        }
    }
}

async function fetchDataUserInfo() {
    const requestParams = {}
    try {
        const response = await apiService.listUserInfo(requestParams)

        quote.value = response.data.userInfo.balance
    } catch (error) {
        // // console.log(error)
        // if (error.response?.data?.code === 401 || error.response?.data?.business_code === 10001) {
        //     alertService.showInfo('请重新登录')
        //     router.push('/login')
        // } else {
        //     alertService.showError(error.response?.data?.message || '发生未知错误！')
        // }
    }
}

onMounted(async () => {
    contentId.value = store.state.contentId
    // console.log('SSS', contentId.value)
    if (contentId.value && contentId.value !== 0) {
        // console.log(contentId.value)
        try {
            const responseContent = await apiService.listWeightLossContentById(contentId.value)
            // console.log(responseContent.data)
            content.input = responseContent.data.content.input
            content.output = responseContent.data.content.showContent
            quote.value = responseContent.data.content.balance

            contentId.value = responseContent.data.content.id
            score.value = responseContent.data.content.score // 更新得分
            outputWordCount.value = responseContent.data.content.showNumber
            const outputNumber = responseContent.data.content.outputNumber
            isNotRemained.value = true // 更新isRemained状态
            if (outputNumber !== outputWordCount.value) {
                isNotRemained.value = false
            }
        } catch (error) {
            if (error.response?.data?.reason === 401) {
                alertService.showInfo('用户未登录')
                // router.push('/login')
            } else {
                alertService.showError(error.response?.data?.message || '发生未知错误！')
            }
        }
    } else {
        fetchDataUserInfo()
    }
})
</script>

<style>
.home {
    display: flex;
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    position: relative;

    .navbar-wrapper-home {
        width: 100%;
        top: 0;
        z-index: 1000; /* 确保导航栏在顶层 */
    }

    .activity-column {
        width: 100%;
        padding: 1% 0;
        text-align: center;
        background-color: #fff4e5; /* 背景颜色 */
        position: relative;
        flex-direction: column; /* 修改为列方向布局 */
        justify-content: space-between;
        top: 6%; /* 位于导航栏下方（假设导航栏高度为60px） */

        .activity-content {
            font-size: 16px;
            color: #995700; /* 字体颜色 */
        }

        .highlight {
            font-weight: bold;
            color: #ff6f00; /* 高亮颜色 */
        }
    }

    .deduplication-container {
        position: relative;
        display: flex;
        flex-direction: row; /* 修改为列方向布局 */
        width: 100%;
        height: 70%;
        top: 10%;

        .slider-container {
            margin-left: auto;
        }

        .deduplication-content {
            position: relative;
            margin: 0 5% 0 5%;
            width: 80%;
            height: 80%;
            display: flex;
            border: 1px solid #002f5a;
            box-shadow: 0 0 10px 2px #002f5a;

            align-items: center;
            padding: 2% 2% 1% 2%;
            flex-direction: column; /* 修改为列方向布局 */
            .deduplication-main {
                display: flex;
                width: 100% !important;
                height: 100% !important;
                justify-content: space-between;

                .deduplication-input,
                .deduplication-output {
                    width: 48%;

                    .el-textarea {
                        height: 100% !important;

                        .el-textarea__inner {
                            width: 100% !important;
                            height: 100% !important;
                        }
                    }
                }
            }

            /* 新增定位容器 */
            .deduplication-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100% !important;
                margin-top: 10px; /* 给顶部添加一些间距 */
                height: 5%;
                flex-direction: row; /* 修改为行方向布局 */
                .deduplication-footer-left,
                .deduplication-footer-right {
                    width: 48% !important;
                    display: flex;
                    justify-content: space-between;
                }
            }

            .action-btn,
            .error-message,
            .word-count,
            .quota,
            .score,
            .show-all-btn {
                display: flex;
            }

            .action-btn,
            .show-all-btn {
                right: 10px;
                background-color: #1890ff;
                color: white;
                border: none;
                padding: 5px 10px;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.3s ease;
            }

            .error-message {
                width: 100% !important;
                text-align: center;
                color: red;
                left: 40px;
            }

            .score {
                left: 60% !important;
            }

            .quota {
                left: 50% !important;
            }

            .show-all-btn:disabled {
                background-color: #d9d9d9;
                color: #a9a9a9;
                cursor: not-allowed;
            }
        }
    }
}
</style>
