<template>
    <div class="login">
        <div class="navbar-wrapper">
            <Navbar />
        </div>
        <div class="login-container">
            <div class="login-dummy">
                <div class="login-main">
                    <h1 class="title">登录</h1>
                    <form class="form-container">
                        <el-form label-position="left" :model="login" label-width="auto">
                            <el-form-item label="手机号">
                                <el-input v-model="login.phone" placeholder="请输入用户名" />
                            </el-form-item>
                            <el-form-item label="密码">
                                <el-input
                                    v-model="login.password"
                                    type="password"
                                    placeholder="请输入密码"
                                    show-password
                                />
                            </el-form-item>
                        </el-form>
                        <el-button class="login-button" @click="loginButton">登录</el-button>

                        <div class="links-div">
                            <span>
                                <RouterLink to="/register" class="register-link"
                                    >立即注册</RouterLink
                                >
                            </span>
                            <span>
                                <RouterLink to="/retrieve-password" class="password-link"
                                    >找回密码</RouterLink
                                >
                            </span>
                        </div>
                    </form>
                </div>
            </div>
            <div class="slider-container">
                <Sidebar />
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue'
import Navbar from '@/components/Navbar.vue'
import { alertService } from '@/utils/alertService.js'
import { apiService } from '@/api/Api.js'
import { useRouter } from 'vue-router'
import Sidebar from '@/components/Sidebar.vue'

const login = reactive({
    phone: '',
    password: ''
})
const router = useRouter()
const loginButton = async () => {
    const requestParams = {
        phone: login.phone,
        password: login.password
    }
    try {
        const response = await apiService.login(requestParams)
        // console.log(response)
        // 检查响应状态码和内容
        if (response.status === 200 && response.data.userInfo.token) {
            // 存储JWT到localStorage
            localStorage.setItem('token', response.data.userInfo.token)
            // 跳转到主页或其他指定页面
            router.push('/')
        } else {
            alertService.showError('用户名或密码错误')
        }
    } catch (error) {
        console.log(error)
        alertService.showError(error.response?.data?.message || '发生未知错误！')
    }
}
</script>

<style>
.login {
    display: flex;
    width: 100%!important;
    height: 100%!important;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .navbar-wrapper {
        width: 100%!important;
        top: 0;
        z-index: 1000; /* 确保导航栏在顶层 */
    }

    .login-container {
        width: 100%!important;

        position: relative;
        display: flex;
        flex-direction: row; /* 修改为列方向布局 */
        margin-top: 7%;

        .slider-container {
            margin-left: auto;
        }
        .login-dummy {
            width: 95%;
            display: flex;
            justify-content: center;

            .login-main {
                width: 50%;
                border-radius: 15px;
                border: 1px solid #002f5a;
                box-shadow: 0 0 10px 2px #002f5a;
                padding: 60px 30px;
                background-color: #fff;
            }
        }
    }
}

.title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
}

.form-container {
    width: 100% !important; /* 确保表单容器撑满可用宽度 */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.links-div {
    text-align: center;
    margin-top: 20px;
}

.register-link,
.password-link {
    text-decoration: none;
    color: #01284e;
    background-color: #f0f2f5; /* 添加背景颜色使其更明显 */
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px; /* Add some margin to space out the links */
}

.register-link:hover,
.password-link:hover {
    background-color: #01284e; /* 鼠标悬停时的背景颜色 */
    color: #fff; /* 鼠标悬停时的字体颜色 */
}

.links-div span {
    display: inline-block;
    margin: 10px 0; /* Margin for spacing out the links vertically */
}

.login-button {
    margin-top: 30px;
    margin-bottom: 10px;
    width: 30%;
    height: auto;
}

.login-button span {
    padding: 5px !important;
}
</style>
