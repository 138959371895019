// store.js
import { createStore } from 'vuex'

export default createStore({
    state: {
        phone: null
    },
    mutations: {
        setPhone(state, phone) {
            state.phone = phone
        },
        setNavigation(state, navigation) {
            state.navigation = navigation
        },
        setRequestParams(state, requestParams) {
            state.requestParams = requestParams
        },
        setContentId(state, requestParams) {
            state.contentId = requestParams
        }
    }
})
