// queryStringUtils.js

export function toQueryString(params) {
    const queryParts = [];
    Object.keys(params).forEach(key => {
        const value = params[key];
        if (value !== null && typeof value === 'object') {
            Object.keys(value).forEach(subKey => {
                if (value[subKey] !== null) {
                    queryParts.push(`${encodeURIComponent(key)}.${encodeURIComponent(subKey)}=${encodeURIComponent(value[subKey])}`);
                }
            });
        } else if (value !== null) {
            queryParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        }
    });
    return queryParts.join('&');
}
