// export function formatDateTime(dateTime) {
//     if (!dateTime) return "";

//     console.log(dateTime);
//     // 从北京时间转换为 UTC 时间（减去 8 小时）
//     const utcDateTime = new Date(dateTime.getTime() - 8 * 60 * 60 * 1000);
//     console.log(utcDateTime);
//     const pad = (num, size = 2) => num.toString().padStart(size, "0");

//     const year = utcDateTime.getUTCFullYear();
//     const month = pad(utcDateTime.getUTCMonth() + 1); // 月份是从 0 开始的
//     const day = pad(utcDateTime.getUTCDate());
//     const hours = pad(utcDateTime.getUTCHours());
//     const minutes = pad(utcDateTime.getUTCMinutes());
//     const seconds = pad(utcDateTime.getUTCSeconds());
//     const milliseconds = pad(utcDateTime.getUTCMilliseconds(), 3); // 毫秒是3位数
//     console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`);

//     return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
// }

export function formatDateTime(dateTime) {
    if (!dateTime) return "";

    console.log(dateTime);
    // 直接使用 dateTime 的 UTC 时间部分，无需手动转换
    const pad = (num, size = 2) => num.toString().padStart(size, "0");

    const year = dateTime.getUTCFullYear();
    const month = pad(dateTime.getUTCMonth() + 1); // 月份是从 0 开始的
    const day = pad(dateTime.getUTCDate());
    const hours = pad(dateTime.getUTCHours());
    const minutes = pad(dateTime.getUTCMinutes());
    const seconds = pad(dateTime.getUTCSeconds());
    const milliseconds = pad(dateTime.getUTCMilliseconds(), 3); // 毫秒是3位数
    console.log(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
}



export function formatTimeToMinute(dateTimeString) {
    if (!dateTimeString) return "";
    const date = new Date(dateTimeString);

    // 转换为北京时间（UTC+8）
    date.setHours(date.getHours() + 8);

    const pad = (num) => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function formatTimeToMinuteUTC(dateTimeString) {
    if (!dateTimeString) return "";
    const date = new Date(dateTimeString);

    // // 转换为北京时间（UTC+8）
    // date.setHours(date.getHours() + 8);

    const pad = (num) => num.toString().padStart(2, '0');

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}




