<template>
    <div class="update-register">
        <div class="navbar-wrapper">
            <Navbar />
        </div>
        <div class="update-register-container">
            <div class="update-register-dummy">
                <div class="update-register-main">
                    <h1 class="title">修改密码</h1>

                    <form class="form-container">
                        <el-form label-position="left" :model="register" label-width="auto">
                            <el-form-item label="手机号">
                                <el-input v-model="register.phone" placeholder="请输入手机号" />
                            </el-form-item>
                            <el-form-item label="旧密码">
                                <el-input
                                    v-model="register.oldPassword"
                                    type="password"
                                    placeholder="请输入旧密码"
                                    show-password
                                />
                            </el-form-item>
                            <el-form-item label="新密码">
                                <el-input
                                    v-model="register.newPassword"
                                    type="password"
                                    placeholder="请输入新密码"
                                    show-password
                                />
                            </el-form-item>
                            <el-form-item label="确认密码">
                                <el-input
                                    v-model="register.confirmNewPassword"
                                    type="password"
                                    placeholder="请再次输入密码"
                                    show-password
                                />
                            </el-form-item>

                            <el-form-item label="验证码">
                                <el-input
                                    v-model="register.verificationCode"
                                    placeholder="输入验证码"
                                    class="verification-input"
                                >
                                    <template #append>
                                        <el-button
                                            class="register-verification-button"
                                            :disabled="!canGetCode"
                                            @click="getVerificationCode"
                                        >
                                            {{ buttonText }}
                                        </el-button>
                                    </template>
                                </el-input>
                            </el-form-item>
                        </el-form>
                        <el-button class="register-confirm-button" @click="registerButton"
                            >更新密码</el-button
                        >
                    </form>
                </div>
            </div>

            <div id="slider-container">
                <Sidebar />
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import Navbar from '@/components/Navbar.vue'
import { apiService } from '@/api/Api.js'
import { alertService } from '@/utils/alertService.js'
import Sidebar from '@/components/Sidebar.vue'
import { useRouter } from 'vue-router'

const register = reactive({
    phone: '',
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    verificationCode: ''
})

// 验证码时间
const canGetCode = ref(true)
const buttonText = ref('获取验证码')
let timer = null
const getVerificationCode = async () => {
    if (timer) return

    canGetCode.value = false
    let countdown = 60
    buttonText.value = `${countdown}秒`

    timer = setInterval(() => {
        countdown -= 1
        buttonText.value = `${countdown}秒`
        if (countdown <= 0) {
            clearInterval(timer)
            timer = null
            canGetCode.value = true
            buttonText.value = '获取验证码'
        }
    }, 1000)

    // console.log('发送验证码到手机')
    const requestParams = {
        phone: register.phone,
        type: 3
    }
    try {
        await apiService.generateVerifyCode(requestParams)
    } catch (error) {
        alertService.showError(error.response?.data?.message || '发生未知错误！')
    }
}

// 找回密码
const router = useRouter()
const registerButton = async () => {
    // 校验确认密码
    if (register.newPassword !== register.confirmNewPassword) {
        alertService.showError('两次密码不一致')
        return
    }
    if (register.newPassword === register.oldPassword) {
        alertService.showError('新密码不能与旧密码相同')
        return
    }
    if (register.newPassword < 6) {
        alertService.showError('密码长度不能小于6位')
        return
    }

    // 参数
    const requestParams = {
        phone: register.phone,
        newPassword: register.newPassword,
        oldPassword: register.oldPassword,
        code: register.verificationCode
    }
    try {
        await apiService.updatePassword(requestParams)
        // 跳转登录界面或显示成功信息
        alertService.showSuccess('密码修改成功')
        // 跳转到登录页
        router.push('/login')
    } catch (error) {
        alertService.showError(error.response?.data?.message || '发生未知错误！')
    }
}
</script>

<style>
.update-register {
    display: flex;
    width: 100%!important;
    height: 100%!important;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .navbar-wrapper {
        width: 100%!important;
        top: 0;
        z-index: 1000; /* 确保导航栏在顶层 */
    }

    .update-register-container {
        width: 100%!important;

        position: relative;
        display: flex;
        flex-direction: row; /* 修改为列方向布局 */
        margin-top: 7%;

        .slider-container {
            margin-left: auto;
        }
        .update-register-dummy {
            width: 95%;
            display: flex;
            justify-content: center;

            .update-register-main {
                width: 50%;
                border-radius: 15px;
                border: 1px solid #002f5a;
                box-shadow: 0 0 10px 2px #002f5a;
                padding: 60px 30px;
                background-color: #fff;
            }
        }
    }
}

.title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 30px;
}

.register-login-div {
    text-align: center;
    margin-bottom: 30px;

    .login-link {
        text-decoration: none;
        color: #01284e;
        background-color: #f0f2f5; /* 添加背景颜色使其更明显 */
        padding: 5px 10px;
        border-radius: 5px;

        &:hover {
            background-color: #01284e; /* 鼠标悬停时的背景颜色 */
            color: #fff; /* 鼠标悬停时的字体颜色 */
        }
    }
}

.form-container {
    width: 100%!important;
    display: flex;
    flex-direction: column;
    align-items: center;

    .register-confirm-button {
        margin-top: 30px;
        width: 40%;
        height: auto;

        span {
            padding: 5px !important;
        }
    }
}
</style>
